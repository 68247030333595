pre {
    border: 1px solid #ccc;
    word-wrap: break-word;
    padding: 6px 10px;
    line-height: 19px;
    margin-bottom: 20px;
}

code {
    border: 1px solid #eaeaea;
    margin: 0px 2px;
    padding: 0px 5px;
    font-size: 12px;
}

pre code {
    border: 0px;
    padding: 0px;
    margin: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
}

pre, code {
    font-family: Consolas, 'Liberation Mono', Courier, monospace;
    color: #333;
    background: #f8f8f8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

pre, pre code {
    font-size: 13px;
}

pre .comment {
    color: #998;
}

pre .support {
    color: #0086B3;
}

pre .tag, pre .tag-name {
    color: navy;
}

pre .keyword, pre .css-property, pre .vendor-prefix, pre .sass, pre .class, pre .id, pre .css-value, pre .entity.function, pre .storage.function {
    font-weight: bold;
}

pre .css-property, pre .css-value, pre .vendor-prefix, pre .support.namespace {
    color: #333;
}

pre .constant.numeric, pre .keyword.unit, pre .hex-color {
    font-weight: normal;
    color: #099;
}

pre .entity.class {
    color: #458;
}

pre .entity.id, pre .entity.function {
    color: #900;
}

pre .attribute, pre .variable {
    color: teal;
}

pre .string, pre .support.value  {
    font-weight: normal;
    color: #d14;
}

pre .regexp {
    color: #009926;
}
