// Responsive Breakpoints
$fixedBreakpoint: 700px;
$widerBreakpoint: 800px;

// Margins
$iiMarginMini:    6px;
$iiMarginSmall:   10px;
$iiMarginNormal:  15px;
$iiMarginLarge:   20px;
$iiMargin2x:      30px;

// Gray paletter
$shade10:   #000000;
$shade20:   #201d18;
$shade30:   #403b30;
$shade35:   #50493c;
$shade40:   #605848;
$shade50:   #817661;
$shade60:   #9d937d;
$shade70:   #b5ad9d;
$shade80:   #cec9be;
$shade85:   #e3ded2;
$shade90:   #f5f3eb;
$shade97:   #fdfdfd;
$shade100:  #ffffff;

// Signature colors

$colorSignature1:  #ff6c00;
$colorSignature2:  #ff9400;
$colorSignature3:  #a66000;
$colorSignature4:  #ffb100;

// Night sky theme

$colorLink-nightSky:   #b5b7f0;
