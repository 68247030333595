// Visibility
// -----------------

.isHidden {
  display: none;
}

// Horizontal alignment
// -----------------

.isBlockCentered { margin-left: auto; margin-right: auto; }
.isTextCentered { text-align: center; }
.isTextRightAligned { text-align: right; }

// Width
// -----------------

.is100PercentWide { width: 100%; }

.isFullScreenWide {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}

// Max width
// -----------------

.isMax100PercentWide { max-width: 100%; }
.isMax50PxWide { max-width: 50px; }
.isMax60PxWide { max-width: 60px; }
.isMax70PxWide { max-width: 70px; }
.isMax80PxWide { max-width: 80px; }
.isMax90PxWide { max-width: 90px; }
.isMax100PxWide { max-width: 100px; }
.isMax110PxWide { max-width: 110px; }
.isMax120PxWide { max-width: 120px; }
.isMax150PxWide { max-width: 150px; }
.isMax160PxWide { max-width: 160px; }
.isMax170PxWide { max-width: 170px; }
.isMax180PxWide { max-width: 180px; }
.isMax200PxWide { max-width: 200px; }
.isMax250PxWide { max-width: 250px; }
.isMax300PxWide { width: 100%; max-width: 300px; }
.isMax350PxWide { width: 100%; max-width: 350px; }
.isMax400PxWide { width: 100%; max-width: 400px; }
.isMax500PxWide { width: 100%; max-width: 500px; }
.isMax600PxWide { width: 100%; max-width: 600px; }
.isMax700PxWide { width: 100%; max-width: 700px; }
.isMax800PxWide { width: 100%; max-width: 800px; }
.isMax900PxWide { width: 100%; max-width: 900px; }

// Magrins
// -----------------

.hasTopMarginSmall { margin-top: $iiMarginSmall; }
.hasBottomMarginSmall { margin-bottom: $iiMarginSmall; }
.hasNoBottomMargin { margin-bottom: 0; }

.hasNoHorizontalPadding {
  margin-right:0;
  margin-left:0;
  .layout-padding {
    padding-left: 0;
    padding-right: 0;
  }
}


// Border
// -----------------

.hasBorderShade90 { border: 1px solid $shade90; }
.hasBorderShade80 { border: 1px solid $shade80; }

// Color
// -----------------

.hasBackgroundColorShade10 { background-color: $shade10; }
