h1:first-child { margin: 40px 0; }

h1 { font-size: 31px; }
h2 { font-size: 26px; }
h3 { font-size: 23px; }
h4 { font-size: 20px; }

h1, h2, h3, h4, h5 {
  color: $shade20;
  margin: 50px 0 30px 0;
  font-weight: 400;
}

h2 {
  border-bottom: 1px solid $shade90;
  padding-bottom: $iiMarginSmall;
}
