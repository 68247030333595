.TagWrap {
  padding-bottom: $iiMarginLarge;
}

.Tag {
  display: inline-block;
  padding: 6px 8px;
  min-width: 100px;

  color: #fff;
  background-color: $colorSignature4;
  text-decoration: none;
  box-shadow: 22px 3px 0 -7px $colorSignature1,
            -13px -4px 0 -6px $colorSignature2,
          -24px -10px 0 -11px $colorSignature3;

  border-radius: 60%/100% 0;
}