* { box-sizing: border-box; }

html { -webkit-text-size-adjust: 100%; }
body {
  color: $shade35;
  font-family: Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
  font-size: 19px;
  word-wrap: break-word;
}

ul, ol {
  margin-bottom: 2em;
  padding-left: 2em;
}

li { margin-bottom: 12px; }

blockquote {
  color: $shade60;
  font-size: 1.15em;
  border-left: 4px solid $shade80;
  margin: 2em 0;
  padding: .5em;
}

p, li { line-height: 1.5; }

hr {
  margin: 0.5em 0;
  border: 0;
  border-top: 1px solid $shade90;
  border-bottom: 1px solid $shade100;
}

pre, code {
  font-size: 0.9em;
  background: $shade97;
}

// Scroll Katex equation if it does not fit on small screens
@media only screen and (max-width: 700px) {
  .katex-display > .katex {
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }
}
