// Generated by autoprefixer from _drawings.scss.original
// See README for instructions

.Drawings {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

.Drawings-link {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 130px;
      -ms-flex: 1 0 130px;
          flex: 1 0 130px;
  text-decoration: none;
  margin: 5px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}

@supports not ((-webkit-flex-wrap: wrap) or (-ms-flex-wrap: wrap) or (flex-wrap: wrap)) {
  .Drawings { display: block; }
  .Drawings-link { display: inline-block; }
}

.Drawings-image {
  width: 100%;
  max-width: 200px;
  display: inline-block;
}