/*

Styles for the equations

Equation consists of 3 parts:

  1. A left aligned text.
  2. A centered image.
  3. A right-aligned text like an equation number.

Example:

<div class='Equation isTextCentered'>
  <span></span><!-- Left aligned text -->
  <span>
    <img class='isMax200PxWide' src='/image/blog/2016-04-16-programming-harmonic-oscillator/001_0010_harmonic_oscillator_equation.png' alt='Harmonic oscillator equation: F=-kx'>.
  </span>
  <span>(1)</span><!-- Right aligned text -->
</div>

*/

.Equation {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
